import { Component } from '@angular/core';
import { ErrorService } from './services/error/error.service';
import { AlertController } from '@ionic/angular';
import { AlertService, AlertType } from './services/error/alert.service';
import { Router,NavigationStart } from '@angular/router';
import { AuthInternalService } from './services/auth/auth-internal.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = environment.appPages;
  constructor(private authinternalservice: AuthInternalService,private router: Router,private errorService:ErrorService,public alertController: AlertController,private alertservice:AlertService) {
    this.errorService.setModal(async (msg)=>{
      //console.log('modal',msg);
      const alert = await this.alertController.create({
        cssClass: 'alert-class',
        header: 'Attention',
        subHeader: 'Erreur',
        message: msg,
        buttons: ['OK']
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      //console.log('onDidDismiss resolved with role', role);
    });

    router.events.subscribe((val) => {
      if(val instanceof NavigationStart ){
        this.alertservice.hide();
      }
    });
    this.authinternalservice.testlog();
  }

  ngOnInit() {
  }


  closeAlert(){
    this.alertservice.hide();
  }
  logout(){
    this.authinternalservice.logout();
  }
}