import { Injectable } from '@angular/core';

export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private type: AlertType;
  private message: string;

  constructor() {
    this.hide();
  }

  show(type: AlertType, message: string) {
    this.message = message;
    this.type = type;
  }

  hide() {
    this.message = '';
  }

  getType(): AlertType {
    return this.type;
  }

  getMessage(): string {
    return this.message;
  }
}
