import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthInternalService {

  public TOKEN_KEY = 'token-tcm';
  public RULES = 'rules-tcm';
  public listrules = null;

  private url = environment.api_url_racine+environment.api_url;

  constructor(private router: Router,private storageservice: StorageService,private http: HttpClient) { }

  isLoggedIn() {
    if (environment.production) {
      return !!this.storageservice.get(this.RULES);
    }else{
      return !!this.storageservice.get(this.TOKEN_KEY);
    }
    //return true;
  }

  setToken(token) {
    this.storageservice.set(this.TOKEN_KEY, token);
  }

  getToken() {
    return this.storageservice.get(this.TOKEN_KEY);
  }

  setRules(rules) {
    this.storageservice.set(this.RULES,JSON.stringify(rules));
  }

  getRules() {
    this.listrules=JSON.parse(this.storageservice.get(this.RULES));
    return this.listrules;
  }

  logout() {
    this.http.get(`${this.url}/logout`, this.getHttpOptions()).subscribe(data => {
      document.querySelector('meta[name="csrf-token"]').setAttribute("content",data["data"]["token"]);
      this.storageservice.clear();
      this.router.navigate(['/identification']);
    },
    err => {
      this.storageservice.clear();
      this.router.navigate(['/identification']);
      console.error(err)
      console.log('KO');
    });
  }

  testlog() {
    if (environment.production) {
      this.http.get(`${this.url}/islog`, this.getHttpOptions()).subscribe(data => {
        if(!data["data"]["islog"]){
          this.storageservice.clear();
        }
      },
      err => {
        this.storageservice.clear();
        console.error(err)
        console.log('KO');
      });
    }else{
      return !!this.storageservice.get(this.TOKEN_KEY);
    }
  }

  getHttpOptions() {
    return {
      headers: this.getHttpHeaders(),
    };
  }

  getHttpHeaders(): HttpHeaders {
    if (environment.production) {
      return new HttpHeaders({
        'X-XSRF-TOKEN': this.getCookie("XSRF-TOKEN")
      });
    }else{
      return new HttpHeaders({
        'Authorization': 'Bearer ' + this.getToken()
      });
    }
  }

  getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null;
  }

  login(params: { email: string, password: string }) {
    if (environment.production) {
      params["_token"]=document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    }
    return this.http.post(`${this.url}/login`, params);
  }

  resetPwd(params: { email: string }) {
    return this.http.post(`${this.url}/resetpwd`, params);
  }

  resetPwdChg(params: { email: string, token : string,password : string, password_confirmation : string }) {
    return this.http.post(`${this.url}/resetpwdchg`, params);
  }

  chgpwd(params: { current_password: string, new_password : string,new_confirm_password : string }) {
    return this.http.post(`${this.url}/chgpwd`, params, this.getHttpOptions());
  }

  chkdroit(rulesstring){
    
    if(rulesstring==""){
      return true;
    }
    if(this.listrules==null||this.listrules.length==0){
      this.listrules=this.getRules();
    }
    if(this.listrules!=null){
      for(let i=0;i<this.listrules.length;i++){
        if(this.listrules[i]==rulesstring){
          return true;
        }
      }
    }
    return false;
  }

  chkdroiturl(url){
    let addPages=environment.appPages;
    
    for(let i=0;i<addPages.length;i++){
      if(addPages[i].url==url){
        if(addPages[i].rule==""){
          return true;
        }else{
          return this.chkdroit(addPages[i].rule);
        }
      }
    }
    return false;
  }
}
