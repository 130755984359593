import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
/***** gaurd */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthInternalService } from './services/auth/auth-internal.service';
//import { LoginPageRoutingModule } from './login/login-routing.module';


@Injectable()
export class CanChklogged implements CanActivate {

  constructor(private router: Router,private authinternalservice: AuthInternalService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if(!this.authinternalservice.isLoggedIn()){
      //redirection page login
      return this.router.createUrlTree(['identification']);
    }

    if(this.authinternalservice.chkdroiturl(state.url)){
      return true;
    }else{
      return this.router.createUrlTree(['identification']);
    }
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'accueil',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'identification',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'mdpoublie',
    loadChildren: () => import('./forgotpwd/forgotpwd.module').then( m => m.ForgotpwdPageModule)
  },
  {
    path: 'chgmdpoublie/:token/:email',
    loadChildren: () => import('./forgotpwd/forgotpwd.module').then( m => m.ForgotpwdPageModule)
  },
  {
    path: 'entreprise',
    loadChildren: () => import('./searchbusiness/searchbusiness.module').then( m => m.SearchbusinessPageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'export',
    loadChildren: () => import('./export/export.module').then( m => m.ExportPageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'suivi',
    loadChildren: () => import('./followed/followed.module').then( m => m.FollowedPageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'suivitest',
    loadChildren: () => import('./followedtest/followedtest.module').then( m => m.FollowedtestPageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'testfichier',
    loadChildren: () => import('./testfile/testfile.module').then( m => m.TestfilePageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'chgmdp',
    loadChildren: () => import('./chgpwd/chgpwd.module').then( m => m.ChgpwdPageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'ws',
    loadChildren: () => import('./webservice/webservice.module').then( m => m.WebservicePageModule)
    ,canActivate: [CanChklogged]
  },
  {
    path: 'detaillog',
    loadChildren: () => import('./modal/detaillog/detaillog.module').then( m => m.DetaillogPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./modal/client/client.module').then( m => m.ClientPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [ CanChklogged ]
})
export class AppRoutingModule {}
