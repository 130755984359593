import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private generateModal: (text) => any = (text: String) => null;

  constructor() {
  }

  manageError(body) {
    const res = this.hasError(body);
    if (res) {
      this.showModal(body.message);
    }
    return res;
  }

  hasError(body) {
    if (body && body.hasOwnProperty('success')) {
      return !body.success;
    }
    return null;
  }

  setModal(generateModal) {
    this.generateModal = generateModal;
  }

  showModal(error) {
    return this.generateModal(error);
  }
}
